import React from "react";
import PropTypes from 'prop-types';

const Header = ({ data }) => {
  const createTitle = () => {
    return { __html: `${data.title}` }
  }

  return (
    <div className="relative -mt-20 py-36 bg-aboutus-header bg-cover bg-center">
      <div className="relative container flex z-10" dangerouslySetInnerHTML={createTitle()} />
      <div className="absolute inset-0 bg-gradient-to-b from-accent to-primary z-0 opacity-80" />
    </div>
  )
}

export default Header;

Header.propTypes = {
  data: PropTypes.object.isRequired,
};
