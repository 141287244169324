import React from "react";
import PropTypes from 'prop-types';

const Intro = ({ data }) => {
  const createDescription = () => {
    return { __html: `${data.description}` }
  }

  return (
    <div className="py-56 bg-bgdark">
      <div className="container grid grid-cols-1 md:grid-cols-6 gap-4">
        <div className="relative md:col-span-4 md:col-start-2">
          <img className="absolute -top-28 -left-12 transform w-52" src="/img/about-us/quotes.svg" />
          <img className="absolute z-20 -bottom-28 -right-12 transform w-52 rotate-180" src="/img/about-us/quotes.svg"/>
          <div className="relative z-10 px-10 py-20 md:p-20 bg-white text-xl shadow-md" dangerouslySetInnerHTML={createDescription()}></div>
          <div className="absolute h-full w-full border-gradient top-7 left-7"></div>
        </div>
      </div>
    </div>
  )
}

export default Intro;

Intro.propTypes = {
  data: PropTypes.object.isRequired,
};
