import React from "react"
import { RiLinkedinBoxFill } from "react-icons/ri"

const Founders = ({ title, data }) => {
  const createTitle = () => {
    return { __html: `${title.title}` }
  }

  return (
      <section>
        <div class="container px-5 py-36 mx-auto max-w-screen-lg mx-auto">
          <div class="text-center mb-20">
            <div dangerouslySetInnerHTML={createTitle()} />
          </div>
          <div class="flex flex-wrap justify-center flex-col md:flex-row sm:-m-4 -mx-4 -mb-10 -mt-4 md:space-y-0 space-y-6 ">
            {data.map((edge, index) => {
              const { name, image, jobTitle, urlLinkedin } = edge.node;
              return (
                <div class="p-4 md:w-1/3 flex flex-col text-center items-center">
                  <div class="w-52 h-52 inline-flex items-center justify-center rounded-full flex-shrink-0">
                    <div>
                    <div className="relative w-52 h-52 min-w-56 bg-gradient-to-b from-primary to-accent rounded-full">  
                      <img
                        src={image.url}
                        alt={name}
                        className={`absolute right-2 ${index % 2 === 0 ? 'bottom-1' : 'top-1'} h-100 w-auto `}
                      ></img>
                      </div>
                    </div>
                  </div>
                  <div class="mt-4 flex flex-col items-center">
                    <h2 class="text-xl font-medium">{name}</h2>
                    <h5 class="text-primary text-base font-medium">
                      {jobTitle}
                    </h5>
                    <a
                      href={urlLinkedin}
                      target="_blank"
                    >
                    <RiLinkedinBoxFill
                      class="mt-1 text-accent"
                      size="1.5rem"
                    />
                    </a>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </section>
  )
}

export default Founders;