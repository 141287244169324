import React from "react";
import { Button } from "src/components/common";
import { Link } from "gatsby";
import scrollTo from 'gatsby-plugin-smoothscroll';
import getStaticContent from "../../../../utils/getStaticContent";

const Timeline = ({ data }) => {
  const mlength = data.length;

  let currentYear;

  return (
    <div className="bg-bgdark">
      <div className="bg-timeline bg-contain bg-bottom bg-no-repeat h-screen w-screen sticky top-0" />
      <div className="relative container flex flex-col p-10 -mt-h-screen">
        <div className="absolute top-0 z-0 left-0 right-0 mx-auto text-center">
          <svg className="inline-block w-full lg:w-2/3 xl:w-1/2 h-auto" viewBox="0 0 833 239" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path className="fill-accent-bg" d="M830 0H0C0 307.2 302 253 453 187.5C507.5 163.859 535.167 157 548.5 166C777.5 320.575 848.5 125 830 0Z"/>
          </svg>
        </div>
        <div className="z-20 w-2/3 m-auto shadow-lg bg-white rounded-2xl p-7 text-center sticky top-20">
          <h3 className="uppercase text-2xl font-medium leading-8 lg:mb-4">
            {getStaticContent("history")}
          </h3>
          <div className="lg:flex space-x-2 justify-center hidden">
            {data.map((edge, index) => {
              const {date, id} = { ...edge.node };
              const year = date.substring(0, 4);
              const newYear = currentYear != year;
              currentYear = year;

              if (newYear) {
                return (
                    <Button onClick={() => scrollTo(`#data-${year}`)} size="sm" key={id} variant="outlined" color="secondary">
                      {year}
                    </Button>
                )
              }

              return
            })}
          </div>
        </div>
        <div>
          {data.map((edge, index) => {
            const {date, title, id} = {...edge.node};
            const year = date.substring(0, 4);
            const newYear = currentYear != year;
            const even = index % 2 == 0;
            const lastItem = index+1;
            currentYear = year;

            return (
              <div key={id} className="text-center">
                {newYear &&
                  <div id={`data-${year}`} className="relative py-12 milestone-target pt-60 -mt-48">
                    <div className={`block w-1 h-10 bg-secondary absolute left-1/2 -bottom-1 z-0`}></div>
                    <h1 className="text-primary text-3xl font-medium" id={year}>{year}</h1>
                  </div>
                }
                <div className={`flex justify-center ${even ? "lg:justify-end" : "lg:justify-start"} ${lastItem === mlength && "lg:justify-center"}`}>
                  <div className={`relative w-auto text-center lg:w-1/2 lg:border-secondary ${even && lastItem !== mlength && "lg:text-left lg:pl-5 lg:border-l-4 lg:-ml-1"} ${!even && lastItem !== mlength && "lg:text-right lg:pr-5 lg:border-r-4 lg:ml-1"} ${lastItem === mlength && "lg:text-center"}`}>
                    <div className={`hidden lg:block h-1 w-20 -mt-0.5 bg-secondary absolute top-1/2 z-0 ${even ? "left-0" : "right-0"} ${lastItem === mlength && "lg:hidden"}`}></div>
                    <div className={`block lg:hidden w-1 h-10 bg-secondary absolute left-1/2 -bottom-1 z-0 ${lastItem === mlength && "hidden"}`}></div>
                    <div className={`relative z-10 text-lg inline-block px-5 py-2 bg-white rounded-xl my-2 milestone`}>
                      {title}
                    </div>
                  </div>
                </div>
              </div>
            )

          })}
        </div>
      </div>
    </div>
  )
}

export default Timeline;
