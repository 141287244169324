import React from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";

import Layout from "src/components/layout/Layout";
import Header from "src/components/about/Header";
import Intro from "src/components/about/Intro";
import Graph from "src/components/about/Graph";
import Founders from "src/components/about/Founders";
import Timeline from "src/components/about/Timeline";
import Contact from "src/components/common/Contact";
import getCurrentLanguage from "../../../utils/getCurrentLanguage";

const AboutUs = ({ data }) => {

  const filterByLocale = (data) => {
    const filteredData = data.edges.filter(field => field.node.locale === getCurrentLanguage())
    return filteredData.length === 1 ? filteredData[0].node : filteredData;
  }

  return (
    <>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>About Us - Invera</title>
      </Helmet>
      {getCurrentLanguage() && (
        <Layout navBarType="light">
          <>
            <Header data={filterByLocale(data.allDatoCmsAboutTitle)} />
            <Intro data={filterByLocale(data.allDatoCmsAboutDescription)} />
            <Founders title={filterByLocale(data.allDatoCmsAboutUsFoundersTitle)} data={filterByLocale(data.allDatoCmsAboutFounder)} />
            <Timeline data={filterByLocale(data.allDatoCmsHistory)} />
            <div id="contact">
              <Contact bg="light" />
            </div>
          </>
        </Layout>
      )}
    </>
  )
}

export const query = graphql`
  {
    allDatoCmsAboutTitle {
      edges {
        node {
          locale
          title
        }
      }
    }
    allDatoCmsAboutDescription {
      edges {
        node {
          locale
          description
        }
      }
    }
    allDatoCmsAboutUsFoundersTitle {
      edges {
        node {
          locale  
          title
        }
      }
    }
    allDatoCmsAboutFounder(sort: { fields: order }) {
      edges {
        node {
          name
          locale
          image {
            url
          }
          jobTitle
          urlLinkedin
        }
      }
    }
    allDatoCmsHistory(sort: { order: ASC, fields: date }) {
      edges {
        node {
          id
          locale
          title
          date
        }
      }
    }
  }
`

export default AboutUs;
